<template>
  <path id="Tracé_554" data-name="Tracé 554" d="M49.334,63.76V61.335H60V63.76Zm-11.611,0-3.816-7.63a31.234,31.234,0,0,1-8.573,1.2C11.365,57.334,0,48.361,0,37.334V26.668C0,16.839,8.9,8.543,21.162,6.944l.345,2.646C10.589,11.012,2.666,18.2,2.666,26.668,2.666,36.225,12.835,44,25.334,44S48,36.225,48,26.668a14.47,14.47,0,0,0-3.766-9.541l2.011-1.749a17.09,17.09,0,0,1,4.422,11.29V37.334c0,7.874-5.809,14.68-14.207,17.941l3.03,6.06h7.177V63.76ZM2.666,37.334c0,9.557,10.169,17.335,22.668,17.335a28.809,28.809,0,0,0,7.364-.959l-1.89-3.778,2.385-1.193,2.075,4.152C42.8,50.067,48,44.164,48,37.334v-1.79c-4.153,6.581-12.739,11.123-22.668,11.123S6.818,42.125,2.666,35.544ZM49.334,58.668V56H63.713v2.667Zm0-5.334V50.668H63.713v2.666ZM24,6.666h1.334a31.532,31.532,0,0,1,8.58,1.193L37.843,0h8.824V2.666H39.49L36.469,8.709a26.162,26.162,0,0,1,6.655,3.705l1.352,1.052L24,29.393Zm2.666,17.275L40.076,13.51a24.133,24.133,0,0,0-4.8-2.417l-2.085,4.171-2.385-1.193,1.894-3.79a28.715,28.715,0,0,0-6.036-.919ZM49.334,13.334V10.668H60v2.667Zm0-5.334V5.334H63.713V8Zm0-5.332V0H63.713V2.668Z" transform="translate(0.5 0.499)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconBi"
}
</script>

<style scoped>

</style>